*{
    font-family: "Roboto Mono", monospace;
}

#mainDiv{
    display: grid;
    grid-template-columns: 25% 75%;

}
.logoText .mainText{
    position: relative;
    /* top: 15px; */
    font-weight: 600;
    font-size: 1.4rem;
    margin: 0px;
    font-family: "Roboto Mono", monospace;
  }
  .logoText .smallText{
    position: relative;
    font-weight: 500;
    /* top: 6px; */
    font-size: 0.7rem;
    margin: 0px;
    /* width: 100%; */
    font-family: "Roboto Mono", monospace;
    
  }
#sideNav{
    background-color: white;
    min-height: 100vh;
    border-right:2px solid #EFEEF6 ;
}
#navItems{
    display: block;
    z-index: 999;
}
#logoFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid red; */
}

#hamburger{
    display: none;
}

.recentThreadBox>div{
    display: flex;
}
.recentThreadBox>div:nth-child(odd) {
    justify-content: flex-end;
  }
.recentThreadBox>div:nth-child(even) {
    justify-content: flex-start;
  }


@media only screen and (max-width: 900px){
    #mainDiv{
        display: grid;
        grid-template-columns: 30% 70%;
    
    }
}
/* @media only screen and (max-width: 820px){
    #mainDiv{
        display: grid;
        grid-template-columns: 25% 75%;
    
    }
} */


@media only screen and (min-width: 750px){
    #navItems{
        display: block;
    }
}
@media only screen and (max-width: 750px){
    #mainDiv{
        display: block;
    
    }
    #sideNav{
        background-color: white;
        border: 1px solid gray;
        border-bottom-left-radius: 5px;
        /* height: 10vh; */
        min-height: fit-content;
    }
    #topLink{
        margin-top: 0px;
    }
    #logoFlex{
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
    }
    #hamburger{
        display: block;
    }
    /* #navItems{
        display: none;
    } */
}

/* .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  } */
  
  /* .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

#appIndex{
    display: grid;
    grid-template-columns: 15% 85%;
}

#sideNav{
    border:1px solid red;
}

table {
    border-collapse: collapse;
    border: none;
  }
  th{
    border: none;
  } */


/* @media only screen and (max-width: 1030px){
    #appIndex{
        display: grid;
        grid-template-columns:20% 80%;
    }
    
}
@media only screen and (max-width: 850px){
    #appIndex{
        display: grid;
        grid-template-columns:25% 75%;
    }
    
}

@media only screen and (max-width: 700px){
    #appIndex{
        display: grid;
        grid-template-columns:100%;
    }
    #sideNav{
        display: flex;
        height:100px;
    }
    #navItems{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    
} */